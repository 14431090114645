import React from 'react'
import { Flex, Text, Box } from 'rebass'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Link from '../Link'
import ExternalLink from '../ExternalLink'
import line from '../line'

import entrustImg from '../images/entrust-img.png'
import bebeImg from '../images/bebe-img.jpg'
import rebeImg from '../images/rebe-img.jpg'
import sebeImg from '../images/sebe-img.jpg'
import baciImg from '../images/baci-img.jpg'
import womenImg from '../images/lucrare-doamne-img.jpg'

const Grid = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-gap: 16px;
  grid-template-rows: repeat(2, minmax(250px, auto));
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`

const Content = styled(({ image, title, children, to, externalTo }) => {
  const LinkCmp = externalTo ? ExternalLink : Link
  return (
    <Flex
      flexDirection="column"
      p={2}
      style={{ border: `1px solid var(--color-bordergray)` }}
    >
      <div
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: 213,
          minHeight: '50%',
        }}
      />
      <Text mb={3} mt={3} style={{ textAlign: 'center' }}>
        {title}
      </Text>
      <Box fontSize={1} color="textgray" style={{ textAlign: 'center' }}>
        {children}
      </Box>
      <Flex
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="center"
        style={{ textAlign: 'center', flex: 1 }}
      >
        <LinkCmp
          color="white"
          bg="red"
          to={externalTo || to}
          fontSize={1}
          px={2}
          my={2}
        >
          Citește »
        </LinkCmp>
      </Flex>
    </Flex>
  )
})``

const IndexPage = () => (
  <Layout>
    <SEO
      title="Educație Biblică prin Extensie"
      keywords={[`edicație biblică`, `fundația ebe`]}
    />
    {line}

    <Box py={2}>
      <Link
        px={0}
        py={0}
        ml={[1, 3]}
        mt={3}
        px={[2, 4]}
        py={3}
        fontSize={2}
        style={{
          float: 'right',
          fontWeight: 200,
          whiteSpace: 'nowrap',
        }}
        to="/contact"
      >
        AFLĂ MAI MULTE
      </Link>
      <Text fontSize={3} lineHeight={2}>
        Fundația EBE este o organizație evanghelică interconfesională care
        susține și dezvoltă programe de echipare de lideri slujitori
        ucenicizatori, prin educație biblică, accesibilă, nonformală, în centre
        de pregătire cu baza în bisericile locale din mișcarea evanghelică.
      </Text>
    </Box>
    <Text fontSize={1} py={2} color="textgray">
      Lucrarea de Educație Biblică prin Extensie se desfășoara pe platforma
      celor 5 sisteme operaționale din cadrul Fundației EBE: 1. Sistemul SEBE -
      Sistem în Cultul Creștin Penticostal; 2. Sistemul REBE - Rețea în Cultul
      Creștin Baptist; 3. Sistemul BEBE - Rețea în Cultul Creștin după
      Evanghelie; 4. Sistemul BACI - Sistem în Bisericile Creștine Autonome; 5.
      Sistemul LF - Sistem de echipare și formare a femeilor evlavioase,
      dedicate slujirii altor femei.
    </Text>
    {line}
    <Grid>
      <Content
        image={entrustImg}
        title="Video EBE"
        externalTo="//vimeo.com/89649422"
      >
        Ce face Fundația EBE?{' '}
        <Box>
          Vezi{' '}
          <ExternalLink to="//vimeo.com/89649422">
            video de prezentare
          </ExternalLink>
        </Box>
      </Content>
      <Content image={sebeImg} title="S.E.B.E" to="/sisteme/s-e-b-e">
        Să dezvoltăm treptat Sistemul de pregătire de slujitori în bisericile
        Penticostale, prin Centre de Pregătire cu baza și sub autoritatea
        bisericilor locale.
      </Content>
      <Content image={rebeImg} title="R.E.B.E" to="/sisteme/r-e-b-e">
        Un sistem național de încredere și colaborare în lucrarea de echipare în
        rândul păstorilor și lucrătorilor baptiști în țară și peste granițe.
      </Content>
      <Content image={bebeImg} title="B.E.B.E" to="/sisteme/b-e-b-e">
        Rețea de lideri slujitori ucenicizatori, bărbați și femei, în bisericile
        Creștine după Evanghelie, cu scopul de a se multiplica și a deveni
        relevanți.
      </Content>
      <Content image={baciImg} title="B.A.C.I" to="/sisteme/b-a-c-i">
        Dezvoltarea continuă a unei mișcări de formare a liderilor slujitori
        ucenicizatori în cadrul bisericilor autonome din Romania.
      </Content>
      <Content
        image={womenImg}
        title="Lucrarea cu femeile"
        to="/sisteme/lucrarea-cu-femeile"
      >
        Echiparea și formarea femeilor evlavioase, dedicate lucrării de slujire
        a altor femei.
      </Content>
    </Grid>

    <Flex>
      <Text
        color="textgray"
        fontSize={1}
        py={3}
        style={{
          margin: 'auto 100px',
          textAlign: 'center',
          fontStyle: 'italic',
        }}
      >
        și, ce-ai auzit de la mine în fața multor martori, încredințează la
        oameni de încredere, care să fie în stare să învețe și pe alții.
        <br />
        <span style={{ whiteSpace: 'nowrap' }}>Timotei 2:2</span>
      </Text>
    </Flex>
  </Layout>
)

export default IndexPage
